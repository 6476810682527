import { Navigate, useLocation } from "react-router";
import isAuthenticated from "./isAuthenticated";

export const RequireAuth = ({ children }) => {
	let auth = isAuthenticated();
	let location = useLocation();

	const access = auth.access;

	const admin = access?.includes("ADMIN");

	const student = access?.includes("STD");

	const instructor = access?.includes("INST");

	const ddc = access?.includes("DDC");

	const web = access?.includes("WEB");

	const temp = access?.includes("TEMP");

	const allRoutes = ["/", "FAQ", "/UserSettings"];

	const studentRoutes = [
		"/SPOT-Student-Search",
		"/SPOT-Student-Search-Results",
		"/USRI-Student-Search",
		"/USRI-Student-Search-Results",
		"/ReportView",
	];

	const instructorRoutes = [
		"/My-SPOT-Reports",
		"/My-USRI-Reports",
		"/MidtermRequest",
		"/request-for-service",
		"/success",
		"/newordisplay",
		"/display",
		"/confirm-exam-dates",
		"/ReportView",
	];

	const ddcRoutes = [
		"/SPOT-DDC-Search",
		"/SPOT-DDC-Reports",
		"/USRI-DDC-Search",
		"/USRI-DDC-Search-Results",
		"/ReportView",
	];

	const webRoutes = [
		"/AddRequisition",
		"/ManageRequisitions",
		"/InstancedClasses",
		"/AutoRequisition",
		"/ViewBlocks",
		"/DepartmentSummary",
		"/MidtermRequest",
		"/request-for-service",
		"/success",
		"/newordisplay",
		"/display",
		"/SPOT-Ordering",
	];

	const tempRoutes = ["/request-for-service", "/success", "/newordisplay", "/display"];

	//needs work to deal with 2x+ access ppl

	if (!auth.token) {
		return <Navigate to="/SignIn" state={{ from: location }} />;
	}
	if (
		((!student && !instructor && !ddc && !web && !admin) || !auth.ccid || !auth.access) &&
		allRoutes.includes(location.pathname)
	) {
		return children;
	}
	if (
		student &&
		(studentRoutes.includes(location.pathname) || allRoutes.includes(location.pathname))
	) {
		return children;
	}
	if (
		instructor &&
		(instructorRoutes.includes(location.pathname) || allRoutes.includes(location.pathname))
	) {
		return children;
	}
	if (ddc && (ddcRoutes.includes(location.pathname) || allRoutes.includes(location.pathname))) {
		return children;
	}
	if (web && (webRoutes.includes(location.pathname) || allRoutes.includes(location.pathname))) {
		return children;
	}
	if (temp && (tempRoutes.includes(location.pathname) || allRoutes.includes(location.pathname))) {
		return children;
	}
	if (admin) {
		return children;
	}
};
