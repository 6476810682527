import axios from "axios";

const url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/");

export async function importSpreadsheet(token, data) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "import_spreadsheet/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: data,
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postItem(token, item) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_item/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: item,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postRoles({ token, role }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_roles/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: role,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postAllClassesClassAdjust({
	token,
	classesToSubmit,
	ccid,
}) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "submit_all_class_adjust/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: { classesToSubmit: classesToSubmit, ccid: ccid },
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postClassIdsAndRoles({ token, listOfNewRows }) {
	//console.log("inapi", listOfNewRows);
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_classids_and_roles/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: listOfNewRows,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postFacultyRank(token, instructor) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_faculty_rank/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: instructor,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function populateQPUsers(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "populate_qp_users/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: {
				populate: "populate",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function postMidtermEvals(token, evaluation) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_midterm_evals/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: evaluation,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postRequisitions(token, requisition) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_requisitions/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: requisition,
		})
			.then((response) => {
				if (response.status === 201) {
					//console.log(response.data);
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postRequisitionBlue(token, requisition) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_requisition_blue/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: requisition,
		})
			.then((response) => {
				if (response.status === 204) {
					//console.log(response.data);
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postItemList(token, itemList) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_item_lists/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: itemList,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postItemsUnique(token, items) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_items_unique/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: items,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postClasses(token, postClass) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_classes/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: postClass,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postClassId({ token, classid }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_classid/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: classid,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postWebUser(token, webUser) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_web_user/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: webUser,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postBlockId(token, blockid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_blockid/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: blockid,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postTempAccess(token, tempAccess) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_temp_access/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: tempAccess,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function postBlockItems(token, blockItems) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_blockitems/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: blockItems,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}
