import React, { useEffect, useState } from "react";
import { InstuctorTable } from "./Data Tables/InstuctorTable";
import { UAspotTable } from "./Data Tables/UAspotTable";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
// import TabList from '@mui/lab/TabList';
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { UAspotBarChart } from "./Data Tables/UAspotBarChart";
import { ReportHeader } from "./ReportHeader";
import { SupplementaryTable } from "./Data Tables/SupplementaryTable";
import { CommentsTable } from "./Data Tables/CommentsTable";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { SearchablePDF } from "../Report Download/SearchablePDF";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

// This function creates a data object with specified properties.
function createData(category, question, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor) {
	return { category, question, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor };
}

// This function creates a data object with specified properties for custom questions.
function createCustomeData(category, question, rsp1, rsp2, rsp3, rsp4, rsp5, scale) {
	return { category, question, rsp1, rsp2, rsp3, rsp4, rsp5, scale };
}

// This function creates a summary data object with specified properties, excluding the question.
function createSummaryData(category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor) {
	return { category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor };
}

// This function summarizes the provided data by category, aggregating response values.
function summarizeData(data) {
	const summary = data.reduce((acc, row) => {
		if (row.category in acc) {
			// Aggregate response values for existing category.
			acc[row.category][0] += Number(row.rsp1);
			acc[row.category][1] += Number(row.rsp2);
			acc[row.category][2] += Number(row.rsp3);
			acc[row.category][3] += Number(row.rsp4);
			acc[row.category][4] += Number(row.rsp5);
		} else {
			// Create a new category entry with aggregated response values.
			acc[row.category] = [
				Number(row.rsp1),
				Number(row.rsp2),
				Number(row.rsp3),
				Number(row.rsp4),
				Number(row.rsp5),
				row.backgroundColor,
			];
		}
		return acc;
	}, {});

	// Convert the summarized data into an array of summary objects.
	const output = Object.keys(summary).map((key) => {
		const [rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor] = summary[key];
		return createSummaryData(key, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor);
	});

	return output;
}

// // Define color codes for categories.
// const colours = {
//     'Design':'#b6e3e9','Utility of course resources':'#a6e3b7','Graded Work':'#fce49b',
//     'Course delivery' : '#f7b4af','Instructional approach' : '#8cb4f9','Class Climate' : '#fac498'
//  }

// Define color codes for categories.
const colours = {
	Design: "rgba(235, 235, 235, 1)",
	"Graded Work": "rgba(235, 235, 235, 1)",
	"Instructional Approach": "rgba(235, 235, 235, 1)",
};

// This component represents a tabbed interface for displaying data.
function TabInterface(class_requisition) {
	const [loaded, setLoaded] = useState(false);
	const [dataCollected, setDataCollected] = useState(false);
	const [spotData, setSpotData] = useState([]);
	const [customSpotData, setCustomSpotData] = useState([]);
	const [spotComments, setSpotComments] = useState({});
	const [value, setValue] = useState("1");
	const [courseINFO, setCourseINFO] = useState([]);

	const [openWindow, setOpenWindow] = useState(false); // Control the visibility of a dialog window
	const [checked, setChecked] = useState([true, true, true, true]);
	const [pdfLoading, setPdfLoading] = useState(false);

	const [loadingWheel, setLoadingWheel] = useState(true);

	const handleCheck0 = (event) => {
		setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
	};

	const handleCheck1 = (event) => {
		setChecked([checked[0], event.target.checked, checked[2], checked[3]]);
	};

	const handleCheck2 = (event) => {
		setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
	};

	const handleCheck3 = (event) => {
		setChecked([checked[0], checked[1], checked[2], event.target.checked]);
	};

	const handleClickOpen = () => {
		setOpenWindow(true);
	};

	const handleClose = () => {
		setOpenWindow(false);
	};

	// Handle tab change.
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		async function reportData() {
			const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/spot_report_data/(${class_requisition})/`;
			const response = await fetch(url, {
				headers: { Authorization: `Token ${sessionStorage.token}` },
			});

			const apiData = await response.json();

			let req_info = [
				apiData[0].req_info.ctitle,
				apiData[0].req_info.inst,
				apiData[0].req_info.term,
				apiData[0].req_info.faculty,
				apiData[0].req_info.dept,
				apiData[0].req_info.reqnum,
				apiData[0].req_info.clsnum,
				apiData[0].req_info.csize,
				apiData[0].req_info.qtally,
				apiData[0].req_info.empid,
			];

			let question_data = [];
			if (apiData[0].req_info.questions.length) {
				let unformated_data = apiData[0].req_info.questions.split("|~^~|");
				// Parse and format question data.
				for (let i = 0; i < unformated_data.length; i++) {
					let entry = unformated_data[i].slice(1, -1).split("|~|");
					entry = createData(
						entry[0].trim(),
						entry[2].trim(),
						entry[3].trim(),
						entry[4].trim(),
						entry[5].trim(),
						entry[6].trim(),
						entry[7].trim(),
						colours[entry[0].trim()]
					);
					question_data.push(entry);
				}
			}

			let custom_questions = [];
			if (apiData[0].customSpotData.questions.length) {
				let unformated_data = apiData[0].customSpotData.questions.split("|~^~|");

				// Parse and format question data.
				for (let i = 0; i < unformated_data.length; i++) {
					let entry = unformated_data[i].slice(1, -1).split("|~|");
					entry = createCustomeData(
						entry[0].trim(),
						entry[2].trim(),
						entry[3].trim(),
						entry[4].trim(),
						entry[5].trim(),
						entry[6].trim(),
						entry[7].trim(),
						entry[8].trim().split(";")
					);
					custom_questions.push(entry);
				}
			}

			let commentData = apiData[0].comments;

			setDataCollected(true);
			return [[question_data, req_info], custom_questions, commentData];
		}

		async function updateLoadingWheel() {
			setLoadingWheel(false);
		}

		if (!loaded) {
			let spotReportData = [reportData()];
			if (dataCollected) {
				Promise.all(spotReportData).then((values) => {
					setSpotData(values[0][0][0]);
					setCourseINFO(values[0][0][1]);
					setCustomSpotData(values[0][1]);
					setSpotComments(values[0][2]);
					setLoaded(true);
				});
			}
		}
		if (loaded && loadingWheel) {
			setTimeout(() => {
				updateLoadingWheel();
			}, 5000);
		}
	}, [loaded, loadingWheel, class_requisition, dataCollected]);

	async function downloadReport() {
		setPdfLoading(true);
		let filename = courseINFO[2].concat(" - ", courseINFO[0], " - ", courseINFO[1], ".pdf");
		await SearchablePDF([class_requisition], checked, filename);
		setPdfLoading(false);
	}

	return (
		<div>
			{ReportHeader(courseINFO)}
			{loadingWheel ? (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					height="50vh" // Set height to 100% of the viewport height
				>
					<CircularProgress />
				</Box>
			) : (
				<Box
					sx={{
						minWidth: "1000px",
						maxWidth: "100%",
						display: "flex",
						flexDirection: "column",
						paddingLeft: "50px",
						paddingRight: "50px" /*border: '3px solid blue'*/,
					}}
				>
					{spotData.length > 0 &&
						sessionStorage.getItem("access").includes("STD") &&
						Number(sessionStorage.getItem("id")) !== courseINFO[9] && (
							<TabContext value={value}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<Tabs onChange={handleChange} value={value} centered={false}>
										<Tab label="Standard SPOT Questions" value="1" />
										<Tab label="Summary Data" value="2" />
									</Tabs>
								</Box>
								<TabPanel value="1">{InstuctorTable(spotData)}</TabPanel>
								<TabPanel value="2">
									{UAspotTable(spotData)}
									<br />
									{UAspotBarChart(courseINFO, summarizeData(spotData))}
								</TabPanel>
							</TabContext>
						)}
					{customSpotData.length < 1 &&
						spotData.length > 0 &&
						((!sessionStorage.getItem("access").includes("STD") &&
							(sessionStorage.getItem("access").includes("INST") ||
								sessionStorage.getItem("access").includes("DDC") ||
								sessionStorage.getItem("access").includes("ADMIN"))) ||
							Number(sessionStorage.getItem("id")) === courseINFO[9]) && (
							<TabContext value={value}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Tabs onChange={handleChange} value={value} centered={false}>
										<Tab label="Standard SPOT Questions" value="1" />
										<Tab label="Summary Data" value="2" />
										<Tab label="Comments" value="3" />
									</Tabs>
									<Button
										variant="contained"
										sx={{ height: "35px" }}
										endIcon={<DownloadIcon />}
										onClick={handleClickOpen}
									>
										PDF Download
									</Button>
									<Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
										<DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
											SPOT REPORT
										</DialogTitle>
										{pdfLoading ? (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													paddingBottom: "20px",
												}}
											>
												<CircularProgress size={80} />
											</Box>
										) : (
											<div>
												<DialogContent>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography>
															Select which elements you would like included in the PDF report:
														</Typography>
														<br></br>
														<FormGroup>
															<FormControlLabel
																control={<Checkbox checked={checked[0]} onChange={handleCheck0} />}
																label="Standard SPOT Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck1} />}
																label="Supplementary Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[2]} onChange={handleCheck2} />}
																label="Summary Data"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[3]} onChange={handleCheck3} />}
																label="Comments"
															/>
														</FormGroup>
													</Box>
												</DialogContent>

												<DialogActions sx={{ justifyContent: "center" }}>
													<Button onClick={handleClose}>Close</Button>
													<Button onClick={downloadReport}>Generate</Button>
												</DialogActions>
											</div>
										)}
									</Dialog>
								</Box>
								<TabPanel value="1">{InstuctorTable(spotData)}</TabPanel>
								<TabPanel value="2">
									{UAspotTable(spotData)}
									<br />
									{UAspotBarChart(courseINFO, summarizeData(spotData))}
								</TabPanel>
								<TabPanel value="3">{CommentsTable(spotComments)}</TabPanel>
							</TabContext>
						)}
					{customSpotData.length > 0 &&
						spotData.length > 0 &&
						((!sessionStorage.getItem("access").includes("STD") &&
							(sessionStorage.getItem("access").includes("INST") ||
								sessionStorage.getItem("access").includes("DDC") ||
								sessionStorage.getItem("access").includes("ADMIN"))) ||
							Number(sessionStorage.getItem("id")) === courseINFO[9]) && (
							<TabContext value={value}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Tabs onChange={handleChange} value={value} centered={false}>
										<Tab label="Standard SPOT Questions" value="1" />
										<Tab label="Supplementary Questions" value="2" />
										<Tab label="Summary Data" value="3" />
										<Tab label="Comments" value="4" />
									</Tabs>
									<Button
										variant="contained"
										sx={{ height: "35px" }}
										endIcon={<DownloadIcon />}
										onClick={handleClickOpen}
									>
										PDF Download
									</Button>
									<Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
										<DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
											SPOT REPORT
										</DialogTitle>
										{pdfLoading ? (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													paddingBottom: "20px",
												}}
											>
												<CircularProgress size={80} />
											</Box>
										) : (
											<div>
												<DialogContent>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography>
															Select which elements you would like included in the PDF report:
														</Typography>
														<br></br>
														<FormGroup>
															<FormControlLabel
																control={<Checkbox checked={checked[0]} onChange={handleCheck0} />}
																label="Standard SPOT Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[1]} onChange={handleCheck1} />}
																label="Supplementary Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[2]} onChange={handleCheck2} />}
																label="Summary Data"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[3]} onChange={handleCheck3} />}
																label="Comments"
															/>
														</FormGroup>
													</Box>
												</DialogContent>

												<DialogActions sx={{ justifyContent: "center" }}>
													<Button onClick={handleClose}>Close</Button>
													<Button onClick={downloadReport}>Generate</Button>
												</DialogActions>
											</div>
										)}
									</Dialog>
								</Box>
								<TabPanel value="1">{InstuctorTable(spotData)}</TabPanel>
								<TabPanel value="2">{SupplementaryTable(customSpotData)}</TabPanel>
								<TabPanel value="3">
									{UAspotTable(spotData)}
									<br />
									{UAspotBarChart(courseINFO, summarizeData(spotData))}
								</TabPanel>
								<TabPanel value="4">{CommentsTable(spotComments)}</TabPanel>
							</TabContext>
						)}

					{customSpotData.length > 0 &&
						spotData.length < 1 &&
						((!sessionStorage.getItem("access").includes("STD") &&
							(sessionStorage.getItem("access").includes("INST") ||
								sessionStorage.getItem("access").includes("DDC") ||
								sessionStorage.getItem("access").includes("ADMIN"))) ||
							Number(sessionStorage.getItem("id")) === courseINFO[9]) && (
							<TabContext value={value}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Tabs onChange={handleChange} value={value} centered={false}>
										<Tab label="Supplementary Questions" value="1" />
										<Tab label="Comments" value="2" />
									</Tabs>
									<Button
										variant="contained"
										sx={{ height: "35px" }}
										endIcon={<DownloadIcon />}
										onClick={handleClickOpen}
									>
										PDF Download
									</Button>
									<Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
										<DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
											SPOT REPORT
										</DialogTitle>
										{pdfLoading ? (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													paddingBottom: "20px",
												}}
											>
												<CircularProgress size={80} />
											</Box>
										) : (
											<div>
												<DialogContent>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography>
															Select which elements you would like included in the PDF report:
														</Typography>
														<br></br>
														<FormGroup>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck0} />}
																label="Standard SPOT Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[1]} onChange={handleCheck1} />}
																label="Supplementary Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck2} />}
																label="Summary Data"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[3]} onChange={handleCheck3} />}
																label="Comments"
															/>
														</FormGroup>
													</Box>
												</DialogContent>

												<DialogActions sx={{ justifyContent: "center" }}>
													<Button onClick={handleClose}>Close</Button>
													<Button onClick={downloadReport}>Generate</Button>
												</DialogActions>
											</div>
										)}
									</Dialog>
								</Box>
								<TabPanel value="1">{SupplementaryTable(customSpotData)}</TabPanel>
								<TabPanel value="2">{CommentsTable(spotComments)}</TabPanel>
							</TabContext>
						)}

					{customSpotData.length < 1 &&
						spotData.length < 1 &&
						!sessionStorage.getItem("access").includes("STD") &&
						(sessionStorage.getItem("access").includes("INST") ||
							sessionStorage.getItem("access").includes("DDC") ||
							sessionStorage.getItem("access").includes("ADMIN")) && (
							<TabContext value={value}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Tabs onChange={handleChange} value={value} centered={false}>
										<Tab label="Comments" value="1" />
									</Tabs>
									<Button
										variant="contained"
										sx={{ height: "35px" }}
										endIcon={<DownloadIcon />}
										onClick={handleClickOpen}
									>
										PDF Download
									</Button>
									<Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
										<DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
											SPOT REPORT
										</DialogTitle>
										{pdfLoading ? (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													paddingBottom: "20px",
												}}
											>
												<CircularProgress size={80} />
											</Box>
										) : (
											<div>
												<DialogContent>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography>
															Select which elements you would like included in the PDF report:
														</Typography>
														<br></br>
														<FormGroup>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck0} />}
																label="Standard SPOT Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck1} />}
																label="Supplementary Questions"
															/>
															<FormControlLabel
																control={<Checkbox checked={false} onChange={handleCheck2} />}
																label="Summary Data"
															/>
															<FormControlLabel
																control={<Checkbox checked={checked[3]} onChange={handleCheck3} />}
																label="Comments"
															/>
														</FormGroup>
													</Box>
												</DialogContent>

												<DialogActions sx={{ justifyContent: "center" }}>
													<Button onClick={handleClose}>Close</Button>
													<Button onClick={downloadReport}>Generate</Button>
												</DialogActions>
											</div>
										)}
									</Dialog>
								</Box>
								<TabPanel value="1">{CommentsTable(spotComments)}</TabPanel>
							</TabContext>
						)}
				</Box>
			)}
		</div>
	);
}

export { TabInterface };
