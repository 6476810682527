import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ReactDOM from 'react-dom';
import html2canvas from 'html2canvas';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';



ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
	ChartDataLabels
);

var responses = 'something'

export const generateOptions = () => {
	return{
    // responsive: true,
    plugins: {
		datalabels: {
			anchor: 'start',
			align: 'start',
			// color: 'black',
			// font: {
			// 	// size: 16,
			//   	weight: 'bold'
			// },
			formatter: function(value, context) {
				let array = ['SD','D','N','A','SA']
				return array[context.datasetIndex]
			  }
		},
	
			
        legend: {
			display: false,
            position: 'bottom',
			labels: {
				font: {
					size: 16
				}
			}
        },
        title: {
            display: true,
			text: ['SPOT Frequency Distributions',responses],
			font: {
				size: 22
			},
		}
    },
	scales: {
        y: {
            ticks: {
                font: {
                    size: 16 // Set the desired font size for y-axis labels
                },
            },
			grid: {
				color: '#A9A9A9' 
			}
        },
		x: {
            ticks: {
                font: {
                    size: 16 // Set the desired font size for y-axis labels
                },
				padding: 20
            },
			grid: {
				color: '#A9A9A9' 
			}
        }
    }
	}
};



function calculatePercentage(value, total) {
	// Check if both parameters are numbers
	if (typeof value !== "number" || typeof total !== "number") {
		return "0.0%";
	}

	// Calculate the percentage and round it to one decimal place
	const percentage = (value / total) * 100;
	const roundedPercentage = percentage.toFixed(1);

	// Format the result as a percentage string
	return `${roundedPercentage}%`;
}

function createData(
	category,
	question,
	rsp1,
	rsp2,
	rsp3,
	rsp4,
	rsp5,
	backgroundColor
) {
	return { category, question, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor };
}

function createCustomeData(
	category,
	question,
	rsp1,
	rsp2,
	rsp3,
	rsp4,
	rsp5,
	scale
) {
	return { category, question, rsp1, rsp2, rsp3, rsp4, rsp5, scale };
}

const colours = {
	Design: [235, 235, 235],
	"Utility of Course Resources": [255, 255, 255],
	"Graded Work": [235, 235, 235],
	"Course Delivery": [255, 255, 255],
	"Instructional Approach": [235, 235, 235],
	"Class Climate": [255, 255, 255],
};


async function collect_report_data(reports){
	const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/spot_report_data/(${reports})/`;
	const response = await fetch(url, {
		headers: { Authorization: `Token ${sessionStorage.token}` },
	});
	const apiData = await response.json();
	let spot_data = []
	for (let index = 0; index < apiData.length; index++){
		let spot_entry = {}
		let req_info = [
			apiData[index].req_info.ctitle,
			apiData[index].req_info.inst,
			apiData[index].req_info.term,
			apiData[index].req_info.faculty,
			apiData[index].req_info.dept,
			apiData[index].req_info.reqnum,
			apiData[index].req_info.clsnum,
			apiData[index].req_info.csize,
			apiData[index].req_info.qtally,
		];
	
		let question_data = [];
		if (apiData[index].req_info.questions.length > 0) {
			let unformated_data = apiData[index].req_info.questions.split("|~^~|");
			// Parse and format question data.
			for (let i = 0; i < unformated_data.length; i++) {
				let entry = unformated_data[i].slice(1, -1).split("|~|");
				entry = createData(
					entry[0].trim(),
					entry[2].trim(),
					entry[3].trim(),
					entry[4].trim(),
					entry[5].trim(),
					entry[6].trim(),
					entry[7].trim(),
					colours[entry[0].trim()]
				);
				question_data.push(entry);
			}
		}
		spot_entry['req_info'] = [req_info, question_data]

		question_data = [];
		if (apiData[index].customSpotData.questions.length > 0) {
			let unformated_data = apiData[index].customSpotData.questions.split("|~^~|");
			// Parse and format question data.
			for (let i = 0; i < unformated_data.length; i++) {
				let entry = unformated_data[i].slice(1, -1).split("|~|");
				entry = createCustomeData(
					entry[0].trim(),
					entry[2].trim(),
					entry[3].trim(),
					entry[4].trim(),
					entry[5].trim(),
					entry[6].trim(),
					entry[7].trim(),
					entry[8].trim().split(";")
				);
				question_data.push(entry);
			}
		}
		spot_entry['customSpotData'] = question_data
		spot_entry['comments'] = apiData[index].comments
		spot_data.push(spot_entry)
	}	
	return spot_data
}



// async function fetchData(url) {
// 	const response = await fetch(url, {
// 		headers: { Authorization: `Token ${sessionStorage.token}` },
// 	});
// 	const data = await response.json();
// 	return data;
// }

// async function numericalData(reqID) {
// 	const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/spot_data/${reqID}/`;
// 	const apiData = await fetchData(url);

// 	let req_info = [
// 		apiData.ctitle,
// 		apiData.inst,
// 		apiData.term,
// 		apiData.faculty,
// 		apiData.dept,
// 		apiData.reqnum,
// 		apiData.clsnum,
// 		apiData.csize,
// 		apiData.qtally,
// 	];

// 	let question_data = [];
// 	if (apiData.questions.length > 0) {
// 		let unformated_data = apiData.questions.split("|~^~|");
// 		// Parse and format question data.
// 		for (let i = 0; i < unformated_data.length; i++) {
// 			let entry = unformated_data[i].slice(1, -1).split("|~|");
// 			entry = createData(
// 				entry[0].trim(),
// 				entry[2].trim(),
// 				entry[3].trim(),
// 				entry[4].trim(),
// 				entry[5].trim(),
// 				entry[6].trim(),
// 				entry[7].trim(),
// 				colours[entry[0].trim()]
// 			);
// 			question_data.push(entry);
// 		}
// 	}
// 	return [req_info, question_data];
// }

// async function SuppData(reqID) {
// 	const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/custom_spot_data/${reqID}/`;
// 	const apiData = await fetchData(url);
// 	let question_data = [];
// 	if (apiData.questions.length > 0) {
// 		let unformated_data = apiData.questions.split("|~^~|");
// 		// Parse and format question data.
// 		for (let i = 0; i < unformated_data.length; i++) {
// 			let entry = unformated_data[i].slice(1, -1).split("|~|");
// 			entry = createCustomeData(
// 				entry[0].trim(),
// 				entry[2].trim(),
// 				entry[3].trim(),
// 				entry[4].trim(),
// 				entry[5].trim(),
// 				entry[6].trim(),
// 				entry[7].trim(),
// 				entry[8].trim().split(";")
// 			);
// 			question_data.push(entry);
// 		}
// 	}
// 	return question_data;
// }

// async function commentsData(reqID) {
// 	const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/spot_comments/${reqID}/`;
// 	const commentData = await fetchData(url);
// 	return commentData;
// }

const getRowSpan = (data, index) => {
	let count = 1;
	while (
		index + count < data.length &&
		data[index].category === data[index + count].category
	) {
		count++;
	}
	return count;
};

async function pdfHeader(pdf, data) {
	pdf.setFont("helvetica", "bold");
	pdf.setFontSize(16);
	pdf.text("Student Perspectives of Teaching (SPOT)", 14, 10);

	pdf.setFont("helvetica", "normal");
	pdf.setFontSize(8);
	pdf.text(data[3], 14, 14);
	pdf.text(data[4], 14, 18);
	const string = "Number of students responding to questionnaire: ".concat(
		data[8],
		" of ",
		data[7],
		" (",
		calculatePercentage(data[8], data[7]),
		")"
	);
	pdf.text(string, 14, 22);

	pdf.setFont("helvetica", "normal");
	pdf.text("Term:       ", 235, 10);
	pdf.text("Class:      ", 235, 14);
	pdf.text("Instructor: ", 235, 18);
	pdf.text("Reqnum:     ", 235, 22);

	pdf.setFont("helvetica", "bold");
	pdf.text(data[2], 250, 10);
	pdf.text(data[0], 250, 14);
	pdf.text(data[1], 250, 18);
	pdf.text(data[5].concat("-", data[6]), 250, 22);
	pdf.line(14, 25, 283, 25);
}

async function pdfFooter(pdf) {
	pdf.setFont("helvetica", "italic");
	pdf.setFontSize(7.5);
	pdf.text(
		"Student surveys are an important part of providing feedback about their perspectives of teaching, but cannot be taken in isolation as a complete evaluation of a course or instructor. Factors outside of an instructor’s control may",
		16,
		// 175
		183
	);
	pdf.text(
		"influence the results. These factors include, but are not limited to:",
		16,
		// 178
		186
	);
	pdf.text("a. completion rate of the survey;", 26, 190);
	pdf.text(
		"b. class size, class level, the Faculty and program in which the course is developed, timing of the class, delivery mode, required versus optional course, accredited program requirements, practicum or clinical contexts,",
		26,
		// 187
		195
	);
	pdf.text(
		"grade expectations, student GPA, age of both students and instructors; and,",
		29,
		// 190
		198
	);
	pdf.text(
		"c. perceived race, gender, age, religion, ability, sexual orientation, and/or ethnicity of the instructor.",
		26,
		// 193
		201
	);
	pdf.text(
		"Small differences in results should not be considered meaningful. Results will be interpreted using the defined scale: Strongly Disagree (SD); Disagree (D); Neither Agree nor Disagree (N); Agree (A); and, Strongly Agree (SA).",
		16,
		// 199
		207
	);
}

async function standardTable(pdf, req_info, data) {
	await pdfHeader(pdf, req_info);;
	const bodyContent = [];

	data.forEach((row, index) => {
		let entry = [];
		if (index === 0 || row.category !== data[index - 1].category) {
			// Add a cell with rowSpan if it's the first row of a category
			entry.push({
				content: row.category,
				rowSpan: getRowSpan(data, index),
				styles: {
					valign: "middle",
					halign: "center",
					fontSize: 8,
					fillColor: colours[row.category],
					textColor: "black",
				}, // Set font size here
			});
		}
		entry.push(
			{
				content: row.question,
				styles: {
					fontSize: 8,
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp1,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp2,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp3,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp4,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp5,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			}
		);
		bodyContent.push(entry);
	});

	const columnHeaders = [
		{
			content: "SPOT DOMAIN",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Question",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Strongly Disagree",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Disagree",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Neither Agree Nor Disagree",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Agree",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Strongly Agree",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
	];

	autoTable(pdf, {
		head: [columnHeaders],
		headStyles: { fillColor: [39, 93, 56], lineWidth: 0.2 },
		columnStyles: {
			0: { cellWidth: 27 },
			1: { cellWidth: 92 },
			2: { cellWidth: 30 },
			3: { cellWidth: 30 },
			4: { cellWidth: 30 },
			5: { cellWidth: 30 },
			6: { cellWidth: 30 },
		},
		body: bodyContent,
		startY: 28,
		theme: "grid",
	});
	pdfFooter(pdf);
	pdf.addPage();
}

async function supplementaryTable(pdf,req_info, data) {
	await pdfHeader(pdf, req_info);
	function arraysAreEqual(arr1, arr2) {
		if (arr1.length !== arr2.length) {
			return false;
		}

		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return false;
			}
		}

		return true;
	}

	const organized_data = [];
	for (let i = 0; i < data.length; i++) {
		let inserted = false;

		for (let j = 0; j < organized_data.length; j++) {
			if (
				organized_data[j][0].category === data[i].category &&
				arraysAreEqual(organized_data[j][0].scale, data[i].scale)
			) {
				organized_data[j].push(data[i]);
				inserted = true;
				break;
			}
		}

		if (!inserted) {
			organized_data.push([data[i]]);
		}
	}

	let startHeight = 28;
	organized_data.forEach((data, index) => {
		let addedHeight = 0;
		const bodyContent = [];
		data.forEach((row, index) => {
			let entry = [];
			if (index === 0 || row.category !== data[index - 1].category) {
				entry.push({
					content: row.category,
					rowSpan: getRowSpan(data, index),
					styles: {
						valign: "middle",
						halign: "center",
						fontSize: 8,
						textColor: "black",
					},
				});
			}
			addedHeight =
				addedHeight + 7 + (Math.ceil(row.question.length / 71) - 1) * 3;

			entry.push(
				{ content: row.question, styles: { fontSize: 8, textColor: "black" } },
				{
					content: row.rsp1,
					styles: { fontSize: 8, halign: "center", textColor: "black" },
				},
				{
					content: row.rsp2,
					styles: { fontSize: 8, halign: "center", textColor: "black" },
				},
				{
					content: row.rsp3,
					styles: { fontSize: 8, halign: "center", textColor: "black" },
				},
				{
					content: row.rsp4,
					styles: { fontSize: 8, halign: "center", textColor: "black" },
				},
				{
					content: row.rsp5,
					styles: { fontSize: 8, halign: "center", textColor: "black" },
				}
			);
			bodyContent.push(entry);
		});

		const columnHeaders = [
			{
				content: "SPOT DOMAIN",
				styles: { fontSize: 8, halign: "center", valign: "middle" },
			},
			{
				content: "Question",
				styles: { fontSize: 8, halign: "center", valign: "middle" },
			},
		];
		for (let i = 0; i < data[0].scale.length; i++) {
			columnHeaders.push({
				content: data[0].scale[i],
				styles: { fontSize: 8, halign: "center", valign: "middle" },
			});
		}
		if (startHeight + 10 + addedHeight + 5 <= 190){
			autoTable(pdf, {
				head: [columnHeaders],
				headStyles: { fillColor: [39, 93, 56], lineWidth: 0.2 },
				columnStyles: {
					0: { cellWidth: 27 },
					1: { cellWidth: 92 },
					2: { cellWidth: 30 },
					3: { cellWidth: 30 },
					4: { cellWidth: 30 },
					5: { cellWidth: 30 },
					6: { cellWidth: 30 },
				},
				body: bodyContent,
				startY: startHeight,
				theme: "grid",
			});
			
		}else{
			pdf.addPage()
			pdfHeader(pdf, req_info);
			startHeight = 28

			autoTable(pdf, {
				head: [columnHeaders],
				headStyles: { fillColor: [39, 93, 56], lineWidth: 0.2 },
				columnStyles: {
					0: { cellWidth: 27 },
					1: { cellWidth: 92 },
					2: { cellWidth: 30 },
					3: { cellWidth: 30 },
					4: { cellWidth: 30 },
					5: { cellWidth: 30 },
					6: { cellWidth: 30 },
				},
				body: bodyContent,
				startY: startHeight,
				theme: "grid",
			});
		}
		startHeight = startHeight + 10 + addedHeight + 5;
	});
	pdf.addPage();
}

async function summaryTable(pdf, req_info, data) {
	
	await pdfHeader(pdf, req_info);
	function createData(category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor) {
		return { category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor };
	}

	function summarizeData(data) {
		const summary = data.reduce((acc, row) => {
			if (row.category in acc) {
				acc[row.category][0] += Number(row.rsp1);
				acc[row.category][1] += Number(row.rsp2);
				acc[row.category][2] += Number(row.rsp3);
				acc[row.category][3] += Number(row.rsp4);
				acc[row.category][4] += Number(row.rsp5);
			} else {
				acc[row.category] = [
					Number(row.rsp1),
					Number(row.rsp2),
					Number(row.rsp3),
					Number(row.rsp4),
					Number(row.rsp5),
					row.backgroundColor,
				];
			}
			return acc;
		}, {});

		const output = Object.keys(summary).map((key) => {
			const [rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor] = summary[key];
			return createData(key, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor);
		});

		return output;
	}
	const summaryData = summarizeData(data);
	const bodyContent = [];

	summaryData.forEach((row, index) => {
		let entry = [];
		if (index === 0 || row.category !== data[index - 1].category) {
			// Add a cell with rowSpan if it's the first row of a category
			entry.push({
				content: row.category,
				styles: {
					valign: "middle",
					halign: "center",
					fontSize: 8,
					fillColor: colours[row.category],
					textColor: "black",
				},
			});
		}
		entry.push(
			{
				content: row.rsp1,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp2,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp3,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp4,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			},
			{
				content: row.rsp5,
				styles: {
					fontSize: 8,
					halign: "center",
					fillColor: colours[row.category],
					textColor: "black",
				},
			}
		);
		bodyContent.push(entry);
	});

	const columnHeaders = [
		{
			content: "SPOT DOMAIN",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Strongly Disagree (SD)",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Disagree (D)",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Neither Agree Nor Disagree (N)",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Agree (A)",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
		{
			content: "Strongly Agree (SA)",
			styles: { fontSize: 8, halign: "center", valign: "middle" },
		},
	];

	autoTable(pdf, {
		head: [columnHeaders],
		headStyles: { fillColor: [39, 93, 56], lineWidth: 0.2 },
		columnStyles: {
			0: { cellWidth: 49 },
			1: { cellWidth: 45 },
			2: { cellWidth: 45 },
			3: { cellWidth: 45 },
			4: { cellWidth: 45 },
			5: { cellWidth: 45 },
		},
		body: bodyContent,
		startY: 28,
		theme: "grid",
	});
	// pdf.addPage();	 

}	


async function summaryChart(pdf, req_info, data){
	responses = 'Responses : '.concat(req_info[8]," of ",req_info[7]," (",calculatePercentage(req_info[8], req_info[7]),")")
	const options = generateOptions();
	const colors = {
		'Strongly Disagree' : 'rgba(231,64,53,255)', //red
		'Disagree' : 'rgba(253,109,0,255)', //orange
		'Neither Agree Nor Disagree' : 'rgba(248,190,0,255)', //yellow
		'Agree': 'rgba(0,192,187,255)',//SKY BLUE:'rgba(10,184,233,255)', //blue
		'Strongly Agree' : 'rgba(48,169,85,255)', //green
	}

	function createData(category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor) {
		return { category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor };
	}

	async function summarizeData(data) {
		const summary = data.reduce((acc, row) => {
			if (row.category in acc) {
				acc[row.category][0] += Number(row.rsp1);
				acc[row.category][1] += Number(row.rsp2);
				acc[row.category][2] += Number(row.rsp3);
				acc[row.category][3] += Number(row.rsp4);
				acc[row.category][4] += Number(row.rsp5);
			} else {
				acc[row.category] = [
					Number(row.rsp1),
					Number(row.rsp2),
					Number(row.rsp3),
					Number(row.rsp4),
					Number(row.rsp5),
					row.backgroundColor,
				];
			}
			return acc;
		}, {});

		const output = Object.keys(summary).map((key) => {
			const [rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor] = summary[key];
			return createData(key, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor);
		});

		return output;
	}

	function getCategories(spotData){
        const categories = spotData.map(item => item.category);
        const uniqueCategoriesSet = new Set(categories);
        const uniqueCategories = [...uniqueCategoriesSet];
        return uniqueCategories
    }

    async function collectData(spotData){
        let responses = ['Strongly Disagree', 'Disagree','Neither Agree Nor Disagree','Agree','Strongly Agree']
        let data = {labels:getCategories(spotData),datasets:[]}
        for (let i = 0; i < responses.length; i++ ){
            let entry = {
                label: responses[i],
                data: [],
                backgroundColor: colors[responses[i]]
            }
            data.datasets.push(entry)
        }
       
        for (let i = 0;i<spotData.length;i++){
            let entry = [spotData[i].rsp1,spotData[i].rsp2,spotData[i].rsp3,spotData[i].rsp4,spotData[i].rsp5]
            for (let j = 0 ; j<entry.length;j++){
                data.datasets[j].data.push(entry[j])
            }
        }
        return data
    }

	const chartData = await collectData(await summarizeData(data))

	const summaryChart = (
        <div >
           <Bar options={options} data={chartData} height={70}/>
        </div>
      );
	
	const container = document.createElement('div');
	document.body.appendChild(container);
	ReactDOM.render(summaryChart, container);
	
	await new Promise((resolve) => setTimeout(resolve, 1500));


	await html2canvas(container ,{scale: 2}).then(function (canvas){
		const imgData = canvas.toDataURL('image/jpeg',2);
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = pdf.internal.pageSize.getHeight()*0.45
		const contentWidth = pdfWidth - 20;
		const contentHeight = pdfHeight - 30;  
		
		
		pdf.addImage(imgData, 'JPEG', 10, 85, contentWidth, contentHeight);
		pdf.addPage(); 
	
		
		// Clean up: Unmount and remove the temporary container
		ReactDOM.unmountComponentAtNode(container);
		// root.unmount()
		document.body.removeChild(container);
	});
		 
}


async function displayComments(pdf,req_info,comment_data){
	await pdfHeader(pdf, req_info);
	let responses = req_info[8]
	let startHeight = 28
	Object.entries(comment_data).forEach(([index, value]) => {
		let addedHeight = 0
		const bodyText = [[
			{
				content: "Question:",
				styles: { fontStyle:'bold',fontSize: 8, halign: "left", valign: "middle",
							textColor: "black", cellWidth:30},
			},
			{
				content: value.q_text,
				styles: { fontSize: 8, halign: "left", valign: "middle",textColor: "black" },
			}]
		]
		addedHeight = addedHeight + 7 + (Math.ceil(value.q_text.length / 210) - 1) * 3;
		if (value.responses[0] === null){
			bodyText.push([
				{
					content: "Response Rate:",
					styles: { fontStyle:'bold',fontSize: 8, halign: "left", valign: "middle",
								textColor: "black", cellWidth: 30},
				},
				{
					content: ''.concat(calculatePercentage(0,responses),' (',0,' of ',responses, ')'),
					styles: { fontSize: 8, halign: "left", valign: "middle",textColor: "black" },
				}])
			bodyText.push([{
				content: "No participants responded to this question.",
				colSpan: 2,
				styles: { fontStyle:'italic',fontSize: 8, halign: "center", valign: "middle",textColor: "red"},
				}])
			addedHeight = addedHeight + 7
		}else{
			bodyText.push(
				[
					{
						content: "Response Rate:",
						styles: { fontStyle:'bold',fontSize: 8, halign: "left", valign: "middle",
									textColor: "black", cellWidth: 30},
					},
					{
						content: ''.concat(calculatePercentage(value.responses.length,responses),' (',
									value.responses.length,' of ',responses, ')'),
						styles: { fontSize: 8, halign: "left", valign: "middle",textColor: "black" },
					}],
			)
			for (let i = 0; i<value.responses.length;i++){
				if (value.responses[i] !== null){
					bodyText.push([{
								content: value.responses[i],
								colSpan: 2,
								styles: { fontSize: 8, halign: "left", valign: "middle",textColor: "black", },
								}])
					addedHeight = addedHeight + 7 + (Math.ceil(value.responses[i].length / 210) - 1) * 3;
				}
			}
		}
		if (startHeight + 10 + addedHeight <= 200){
			autoTable(pdf,{
				body: bodyText,
				startY: startHeight,
				theme: "grid"
			})
		} else {
			if (startHeight > 28 ) {
				pdf.addPage()
				pdfHeader(pdf, req_info);
			}
			startHeight = 28
			autoTable(pdf,{
				body: bodyText,
				startY: startHeight,
				theme: "grid"
			})
		}
		startHeight = startHeight + 10 + addedHeight;
	});
	pdf.addPage();
}


const SearchablePDF = async (selectedIDs, checked, filename) => {
	const pdf = new jsPDF("landscape", "mm", "a4");
	/* const lastItem = await findLastTrueIndex(checked); */
	const spot_data = await collect_report_data(selectedIDs)
	for (let i = 0; i < spot_data.length; i++) {
		let req_info = spot_data[i].req_info
		let customSpotData = spot_data[i].customSpotData
		let comments = spot_data[i].comments

		if (checked[0] === true && req_info[1].length > 1) {
			await standardTable(pdf, req_info[0], req_info[1]);
		}

		if (checked[1] === true && customSpotData.length > 0) {
			await supplementaryTable(pdf, req_info[0], customSpotData);
		}

		if (checked[2] === true && req_info[1].length > 0) {
			await summaryTable(pdf, req_info[0], req_info[1]);
			await summaryChart(pdf, req_info[0], req_info[1]);
		}

		if (checked[3] === true) {
			await displayComments(pdf,req_info[0],comments.results)
		}

		const last = i === spot_data.length - 1;
		if (last) {
			pdf.deletePage(pdf.getNumberOfPages());
			pdf.save(filename);
		}
			
	}
};

export { SearchablePDF };
