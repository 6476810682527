import axios from "axios";

const url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/");
const url2 = process.env.REACT_APP_BACKEND_HOST.concat("/auth/");

export async function sendMidtermEmails(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "GET",
			url: url + "send_midterm_emails/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function checkMidtermEmails(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "GET",
			url: url + "check_midterm_emails/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function checkInstructorResultsEmail(token, deptId) {
	return new Promise((resolve, reject) => {
		axios({
			method: "GET",
			url: url + "check_instructor_results_email/".concat(`${deptId}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 404) {
					resolve({ error: "User not found" });
				}
				reject(error);
			});
	});
}

export async function checkAllDepartmentsForResultsEmail(token, deptId) {
	return new Promise((resolve, reject) => {
		axios({
			method: "GET",
			url: url + "check_all_departments_for_results_email/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 404) {
					resolve({ error: "Not Found" });
				}
				reject(error);
			});
	});
}

export async function sendInstructorResultsEmail(token, deptId) {
	return new Promise((resolve, reject) => {
		axios({
			method: "GET",
			url: url + "send_instructor_results_email/" + deptId + "/",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}
export async function getLDAPUserByEmpId(token, empid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url2 + "employee_number_lookup".concat(`/${empid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);

				reject(error);
			});
	});
}

export async function getLDAPUserBySearchField(token, value, searchField) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "get_user_from_LDAP".concat(`/${value}`).concat(`/${searchField}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getAutoRolesByDept(token, deptid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_autoroles_by_dept".concat(`/${deptid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getBlockIds(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_blockids/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getBlockIdsByDept(token, deptid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_blockids_by_dept".concat(`/${deptid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getBlockItemsByBlockId(token, blockId) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_blockitems_by_blockid".concat(`/${blockId}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getCombinedClassIds(token, deptid, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_combined_class_ids".concat(`/${deptid}`).concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getItemCategories(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_itemcategories/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getItemsData(token, itemnos) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_items_by_itemnos/?itemnos=".concat(`${itemnos}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getAllBlueCourses(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "get_all_blue_courses/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log("error");
				console.log(error);
			});
	});
}

export async function getAllItems(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_items/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getAllBlockItems(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_all_blockitems/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getAllClassIds(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classids/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getBlockItemsByBlockId1AndBlockId3(token, blockId1, blockId3) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url:
				url +
				"fetch_blockitems_by_blockid1_and_blockid3/".concat(`${blockId1}`).concat(`/${blockId3}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log("error");
				console.log(error);
			});
	});
}

export async function getClassesRequisitionData(token, classids) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classes_requisition_data/?classids=".concat(`${classids}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getClassIdsByDeptIdAndTerm(token, deptid, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classids_by_dept_and_term/".concat(`${deptid}`).concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getAdjustedClassIdsByDeptIdAndTerm(token, deptid, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url:
				url + "fetch_adjusted_classids_by_dept_and_term/".concat(`${deptid}`).concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getClassIdsAutoGeneration(token, deptid, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classids_auto_generation/".concat(`${deptid}`).concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getClassIdsByTerm(token, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classids_by_term".concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getClassIdsByFetchRolesByEmpId(token, empid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classids_by_fetch_roles_by_empid".concat(`/${empid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getInstancedClassesByClassId(token, classid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_instanced_classes_by_classid".concat(`/${classid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log("error");
				console.log(error);
			});
	});
}

export async function getClassesByTerm(token, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classes_by_term/".concat(`${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getClassesByDeptIdAndTerm(token, deptid, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classes_by_dept_and_term/".concat(`${deptid}`).concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getClassesByReqnums(token, reqnums) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classes_by_reqnums/?reqnums=".concat(`${reqnums}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getClassesByReqnum(token, reqnum) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_classes_by_reqnum/".concat(`${reqnum}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getDepartments(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_departments/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getFacultyRank(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_faculty_rank/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getFacultyRankByEmpid(token, empid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_faculty_rank_by_empid".concat(`/${empid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getFacultyRankByCCID(token, ccid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_faculty_rank_by_ccid".concat(`/${ccid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export async function getMidtermEvals(token, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_midterm_evals".concat(`/${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getTerms(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "terms_list/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRolesByClassIds(token, classids) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_roles_by_multiclass/?classids=".concat(`${classids}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRolesByClassId(token, classid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_roles_by_classid".concat(`/${classid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getReqnum(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_reqnum/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getHighestItemNo(token, itmtyp) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_highest_itemno".concat(`/${itmtyp}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getHighestItemNo20000(token, itmtyp) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_highest_itemno_20000".concat(`/${itmtyp}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getItemListByReqnum(token, reqnums) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_itemlist_by_reqnum/?reqnums=".concat(`${reqnums}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRequisitionsByCCID(token, ccid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_requisitions_by_ccid/".concat(`${ccid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRequisitionNumbersByCCID(token, ccid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_requisition_numbers_by_ccid/".concat(`${ccid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRequisitionNumbersAll(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_requisition_numbers_all/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getRequisitionsAllData(token, ccid, termNumber) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_requisitions_all_data/".concat(`${ccid}/`).concat(`${termNumber}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getUsersForUsersTable(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_users_for_users_table/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function propagateDDCTable(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "propagate_ddc_table/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function propagateClassIDsAndRoles(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "propagate_classids_and_roles/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function propagateRoles(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "propagate_roles/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getStudentEnrollment(token, term) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "get_student_enrollment/".concat(`${term}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getReportsNotifications(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_closed_requisitions_no_reports/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getSTDUsriSearchParams(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "std_info_usri/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getUrgentRequisitions(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_urgent_requisitions/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getUrgentMidtermFeedback(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_urgent_midterm_feedback/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getIncomingRequisitions(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_incoming_requisitions/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function getIncomingMidtermFeedback(token) {
	return new Promise((resolve, reject) => {
		axios({
			method: "get",
			url: url + "fetch_incoming_midterm_feedback/",
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}
