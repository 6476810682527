//Begin Library/Built-in Imports----------------------------------------------
import React, { useEffect } from "react";

import MasterNavbar from "./components/Navbar/MasterNavbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import { ReactQueryDevtools } from "react-query/devtools";
//End Library/Built-in Imports-----------------------------------------------

//Begin Local Imports--------------------------------------------------------
import { RequireAuth } from "./components/Utils/RequireAuth";
import { TokenContext } from "./context/TokenContext";
//End Local Imports----------------------------------------------------------

//Begin Pages Imports--------------------------------------------------------
//General Pages
import SignIn from "./pages/Access/SignIn";
import useToken from "./pages/Access/useToken";
import HomeLanding from "./pages/Home Landing/homeLanding";
/* import NotSupported from "./pages/Home Landing/NotSupport"; */
import Help from "./pages/FAQ/Help";
import "./App.css";

//Admin
import AddUser from "./pages/Admin/AddUser";
import AdminUtils from "./pages/Admin/AdminUtils";
import TrackRequisitions from "./pages/Admin/TrackRequisitions";
import ReportGeneration from "./pages/Admin/ReportGeneration";
import AvailableDDCReports from "./pages/Reports/Report Results/USRI/AvailableDDCReports";

//Midterms
import MidtermRequest from "./pages/Midterm/MidtermRequest";
import MidtermView from "./pages/Midterm/MidtermView";
import MidtermQuestionSets from "./pages/Midterm/MidtermQuestionSets";

//Reports
import SPOTDDCSearch from "./pages/Reports/Report Search/SPOT/SPOTDDCSearch";
import USRIDDCSearch from "./pages/Reports/Report Search/USRI/USRIDDCSearch";
import USRIStudentSearch from "./pages/Reports/Report Search/USRI/USRIStudentSearch";
import MySPOTReports from "./pages/Reports/Report Results/SPOT/MySPOTReports";
import SPOTDDCReports from "./pages/Reports/Report Results/SPOT/SPOTDDCReports";
import SPOTStudentSearch from "./pages/Reports/Report Search/SPOT/SPOTStudentSearch";
import SPOTFacultyDepartmentSearch from "./pages/Reports/Report Search/SPOT/SPOTFacultyDepartmentSearch";
import SPOTFacultyDepartmentReports from "./pages/Reports/Report Results/SPOT/SPOTFacultyDepartmentReports";
import SPOTStudentSearchResults from "./pages/Reports/Report Results/SPOT/SPOTStudentSearchResults";
import ReportView from "./pages/Reports/Visual Display/ReportView";
import USRIDDCSearchResults from "./pages/Reports/Report Results/USRI/USRIDDCSearchResults";
import MyUSRIReports from "./pages/Reports/Report Results/USRI/MyUSRIReports";

//Requisitions
import AddRequisition from "./pages/Requisition/Manual/AddRequisition/AddRequisition";
import ModifyRequisition from "./pages/Requisition/Manual/ModifyRequisition/ModifyRequisition";
import AutoRequisition from "./pages/Requisition/Auto/AutoRequisition";
import SPOTOrdering from "./pages/Requisition/Class Adjustments/SPOTOrdering";

//Request For Service
import Request from "./pages/RFS/Request";
import ReqSuccess from "./pages/RFS/ReqSuccess";
import PostSubmission from "./pages/RFS/PostSubmission";
import DisplayRequests from "./pages/RFS/DisplayRequests";

//User
import UserSettings from "./pages/User/UserSettings";

//Utilities
import AddBlocks from "./pages/Utilities/AddBlocks/AddBlocks";
import ViewBlocks from "./pages/Utilities/AddBlocks/ViewBlocks";
import AddItem from "./pages/Utilities/AddItems/AddItems";
import DepartmentSummary from "./pages/Utilities/DepartmentSummary/DepartmentSummary";
import InstancedClasses from "./pages/Utilities/InstancedClasses/InstancedClasses";
import ConfirmExamDates from "./pages/Exam/ConfirmExamDates";
import USRIStudentSearchResults from "./pages/Reports/Report Results/USRI/USRIStudentSearchResults";

//End Pages Imports-------------------------------------------------------

const queryClient = new QueryClient();

function App() {
	const [disabled, setDisabled] = React.useState(false);
	const [loginError, setLoginError] = React.useState("");
	const { token, setToken } = useToken(setDisabled, setLoginError);
	const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

	const theme = createTheme({
		palette: {
			primary: {
				light: "#007A33",
				main: "#275d38",
				dark: "#003216",
				contrastText: "#fff",
			},
			secondary: {
				light: "#fcc335",
				main: "#FFB600",
				dark: "#d19500",
				contrastText: "#000",
			},
			invertedPrimary: {
				light: "#fff",
				main: "#fff",
				dark: "#DEDEDE",
				contrastText: "#275d38",
			},
			primaryLight: {
				main: "#007A33",
				contrastText: "#fff",
			},
			primaryDark: {
				main: "#003216",
				contrastText: "#fff",
			},
			greys: {
				light: "#E0E0E0",
				main: "#E0E0E0",
				dark: "#bdbdbd",
				contrastText: "#000",
			},
			navText: {
				light: "#797b7d",
				main: "##4c4c4d",
				dark: "#252526",
				contrastText: "#fff",
			},
			greysDark: {
				light: "#a6a8ab",
				main: "#929496",
				dark: "#797b7d",
				contrastText: "#fff",
			},
			mitra: {
				main: "#dda2fc",
			},
			george: {
				main: "#f74367",
			},
			classids: {
				locked: "#a6a8ab",
				unsuitable: "#f74367",
				ending: "#f7a574",
				available: "#007A33",
			},
		},

		typography: {
			fontFamily: "Roboto, sans-serif",
			button: {
				textTransform: "none",
			},
		},

		breakpoints: {
			values: {
				sm: 0,
				md: 500,
				lg: 700,
				xl: 1000,
			},
		},
	});

	const [alerted, setAlerted] = React.useState(false);

	if (isMobile) {
		if (!alerted) {
			alert(
				"This application requires a minimum resolution width of 1200 pixels. If your resolution is lower than this, your experience will be heavily impacted."
			);
			setAlerted(true);
			/* return <NotSupported />; */
		}
	}

	//if token exists and current route is /SignIn, route to /

	if (token && window.location.pathname === "/SignIn") {
		window.location.href = "/";
	}

	if (
		!token &&
		window.location.pathname !== "/SignIn" &&
		window.location.pathname !== "/midterm-question-sets" &&
		window.location.pathname !== "/FAQ"
	) {
		window.location.href = "/SignIn";
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<TokenContext.Provider value={token}>
						<Router>
							<MasterNavbar />
							<Routes>
								<Route
									path="/SignIn"
									element={
										<SignIn
											setToken={setToken}
											disabled={disabled}
											setDisabled={setDisabled}
											loginError={loginError}
										/>
									}
								/>

								<Route
									path="/"
									element={
										<RequireAuth>
											<HomeLanding />
										</RequireAuth>
									}
								/>
								<Route
									path="/request-for-service"
									element={
										<RequireAuth>
											<Request />
										</RequireAuth>
									}
								/>
								<Route
									path="/success"
									element={
										<RequireAuth>
											<ReqSuccess />
										</RequireAuth>
									}
								/>
								<Route
									path="/newordisplay"
									element={
										<RequireAuth>
											<PostSubmission />
										</RequireAuth>
									}
								/>
								<Route
									path="/display"
									element={
										<RequireAuth>
											<DisplayRequests />
										</RequireAuth>
									}
								/>
								<Route
									path="/confirm-exam-dates"
									element={
										<RequireAuth>
											<ConfirmExamDates />
										</RequireAuth>
									}
								/>
								<Route path="/FAQ" element={<Help />} />
								<Route
									path="/SPOT-DDC-Search"
									element={
										<RequireAuth>
											<SPOTDDCSearch />
										</RequireAuth>
									}
								/>
								<Route
									path="/USRI-DDC-Search"
									element={
										<RequireAuth>
											<USRIDDCSearch />
										</RequireAuth>
									}
								/>
								<Route
									path="/USRI-Student-Search"
									element={
										<RequireAuth>
											<USRIStudentSearch />
										</RequireAuth>
									}
								/>
								<Route
									path="/USRI-Student-Search-Results"
									element={
										<RequireAuth>
											<USRIStudentSearchResults />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-DDC-Reports"
									element={
										<RequireAuth>
											<AvailableDDCReports />
										</RequireAuth>
									}
								/>
								<Route
									path="/ReportGeneration"
									element={
										<RequireAuth>
											<ReportGeneration />
										</RequireAuth>
									}
								/>
								<Route
									path="/My-SPOT-reports"
									element={
										<RequireAuth>
											<MySPOTReports />
										</RequireAuth>
									}
								/>
								<Route
									path="/My-USRI-reports"
									element={
										<RequireAuth>
											<MyUSRIReports />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-DDC-Reports"
									element={
										<RequireAuth>
											<SPOTDDCReports />
										</RequireAuth>
									}
								/>
								<Route
									path="/USRI-DDC-Search-Results"
									element={
										<RequireAuth>
											<USRIDDCSearchResults />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-Student-Search"
									element={
										<RequireAuth>
											<SPOTStudentSearch />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-Student-Search-Results"
									element={
										<RequireAuth>
											<SPOTStudentSearchResults />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-Faculty-Department-Search"
									element={
										<RequireAuth>
											<SPOTFacultyDepartmentSearch />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-Faculty-Department-Reports"
									element={
										<RequireAuth>
											<SPOTFacultyDepartmentReports />
										</RequireAuth>
									}
								/>
								<Route
									path="/TrackRequisitions"
									element={
										<RequireAuth>
											<TrackRequisitions />
										</RequireAuth>
									}
								/>
								<Route
									path="/AddUser"
									element={
										<RequireAuth>
											<AddUser />
										</RequireAuth>
									}
								/>
								<Route
									path="/AdminUtils"
									element={
										<RequireAuth>
											<AdminUtils />
										</RequireAuth>
									}
								/>
								<Route
									path="/AddRequisition"
									element={
										<RequireAuth>
											<AddRequisition />
										</RequireAuth>
									}
								/>
								<Route
									path="/SPOT-Ordering"
									element={
										<RequireAuth>
											<SPOTOrdering />
										</RequireAuth>
									}
								/>
								<Route
									path="/ManageRequisitions"
									element={
										<RequireAuth>
											<ModifyRequisition />
										</RequireAuth>
									}
								/>
								<Route
									path="/InstancedClasses"
									element={
										<RequireAuth>
											<InstancedClasses />
										</RequireAuth>
									}
								/>
								<Route
									path="/AutoRequisition"
									element={
										<RequireAuth>
											<AutoRequisition />
										</RequireAuth>
									}
								/>
								<Route
									path="/ViewBlocks"
									element={
										<RequireAuth>
											<ViewBlocks />
										</RequireAuth>
									}
								/>
								<Route
									path="/DepartmentSummary"
									element={
										<RequireAuth>
											<DepartmentSummary />
										</RequireAuth>
									}
								/>
								<Route
									path="/MidtermRequest"
									element={
										<RequireAuth>
											<MidtermRequest />
										</RequireAuth>
									}
								/>
								<Route path="/midterm-question-sets" element={<MidtermQuestionSets />} />
								<Route
									path="/UserSettings"
									element={
										<RequireAuth>
											<UserSettings />
										</RequireAuth>
									}
								/>
								<Route
									path="/ReportView"
									element={
										<RequireAuth>
											<ReportView />
										</RequireAuth>
									}
								/>
								<Route
									path="/MidtermView"
									element={
										<RequireAuth>
											<MidtermView />
										</RequireAuth>
									}
								/>
								<Route
									path="/AddItem"
									element={
										<RequireAuth>
											<AddItem />
										</RequireAuth>
									}
								/>
								<Route
									path="/AddBlocks"
									element={
										<RequireAuth>
											<AddBlocks />
										</RequireAuth>
									}
								/>
							</Routes>
						</Router>
					</TokenContext.Provider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ThemeProvider>
		</LocalizationProvider>
	);
}

export default App;
